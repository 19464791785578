import { useEffect, useState } from 'react';
import { Documento, ModalProps } from './types';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, CaptchaContent, Content } from './styles';
import ModalCustom from '../ModalCustom';
import { message } from 'antd';

const REACT_APP_SITE_KEY = '6Le38bQpAAAAACwrxYFZlc6AN8Di_mKHCpgMi91f';

export const EditalDownloadModal = ({
  edital,
  isOpen,
  setEditalInfo,
  onClose,
}: ModalProps) => {
  const [token, setToken] = useState<string | null>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTokenAndEditalInfo = async () => {
      try {
        setLoading(true);

        // Requisição POST para obter o token
        // const response = await fetch(
        //   'https://bbmnet-cadastro-editais-backendz-z7knklmt7a-rj.a.run.app/api/EditaisInternal/login',
        //   {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //       username: '242742024205827141580',
        //       password: 'dj=JLSLFefWnKFEwpQR08%pquilJsQVJ',
        //     }),
        //   },
        // );

        const data = await response.json();
        const accessToken = data.accessToken;

        // Requisição GET para obter as informações do edital
        const editalResponse = await fetch(
          `https://back-v3.mageserver.com.br/get/edital/${edital.uniqueId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const editalInfo = await editalResponse.json();

        // Captura o downloadLink do primeiro índice
        const downloadLink = editalInfo.documentos[0]?.downloadLink;
        if (downloadLink) {
          edital.relatoriosDoEdital = downloadLink; // Armazenar no objeto edital
        }

        setEditalInfo && setEditalInfo(editalInfo); // Atualiza as informações do edital
      } catch (err) {
        console.error(err);
        message.error('Falha ao obter informações do edital.');
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchTokenAndEditalInfo();
    }
  }, [isOpen, edital, setEditalInfo]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const documentoLink = edital.relatoriosDoEdital;

      if (documentoLink) {
        window.open(documentoLink, '_blank');
      } else {
        console.log('Falha ao obter o link do documento');
        message.error('Falha ao obter o link do documento');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCustom isOpen={isOpen} setModalState={onClose} width="390px">
      <Content>
        <div>
          <h3>Caso queira visualizar o edital, clique abaixo:</h3>
          <CaptchaContent>
            <ReCAPTCHA
              sitekey={REACT_APP_SITE_KEY}
              onChange={(key) => setToken(key)}
            />
          </CaptchaContent>
          <Button disabled={!token && !loading} onClick={handleSubmit}>
            Visualizar Edital
          </Button>
        </div>
      </Content>
    </ModalCustom>
  );
};
