export const FileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="25"
      fill="none"
      viewBox="0 0 19 25"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16 3h2v21H1V3h2"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 1h5c1.1 0 2 .9 2 2v2H5V3c0-1.1.9-2 2-2zM5 10h9M5 15h9"
      />
    </svg>
  );
};
