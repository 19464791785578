import styled from 'styled-components';

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;


  label {
    font-weight: bold;
    color: ${({ theme }) => theme.light};
    font-size: 1.6rem;
    margin-left: 4px;
  }

  input {
    width: 100%;
    padding: 12px;
    font-size: 1.4rem;
    font-weight: 500;
    &::placeholder {
      font-weight: 500;
      color: gray;
    }
  }
`;
