import styled from 'styled-components';

export const HomeContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.light};
  display: grid;
  gap: 0em;


  .selector {
    width: 230px;
  }
  section {
    header {
      padding: 1em 5em 3em 5em;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      div.titles {
        flex: 1;

        h4 {
          color: ${({ theme }) => theme.primary};
          font-size: 3.8rem;
          line-height: 5.4rem;
          font-weight: bold;
        }

        h6 {
          color: ${({ theme }) => theme.secondary};
          font-size: 2.4rem;
          line-height: 3.6rem;
          font-weight: 400;
        }
      }

      div.newsletter {
        display: flex;
        flex-direction: column;
        gap: 12px;

        span {
          color: ${({ theme }) => theme.primary};
          font-size: 1.6rem;
          font-weight: bold;
        }

        button.register {
          display: grid;
          place-items: center;
          background-color: ${({ theme }) => theme.primary};
          color: ${({ theme }) => theme.light};
          font-size: 1.6rem;
          font-weight: bold;
          padding: 12px 24px;
          border-radius: 5px;
          transition: filter 0.2s ease-in-out;

          &:hover {
            filter: brightness(0.8);
          }
        }
      }
    }
  }

  main {
    border-radius: 12px;
    background-color: #f8f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-right: 0px;
    padding-bottom: 32px;

    .blue {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .divone {
      background-color: ${({ theme }) => theme.primary};
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      width: 100%;
      min-height: 7em; /* Increase the minimum height */

      gap: 0;
      box-sizing: border-box; /* Include padding and border in the element's total width and height */
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .palavra-chave::placeholder {
      color: gray;
      padding-left: 0em;
    }

    .bmmNPu {
      gap: 0px;
    }

    input {
      background-color: ${({ theme }) => theme.light};

      padding: 0 0 0 0;
      border-radius: 5px;
    }

    .filter-button {
      background-color: #209ddb;
      color: white;
      font-size: 1.6rem;
      font-weight: bold;
      margin: 1.4em 0.1em;
      width: 7%;
      height: 2.5em;
      transition: filter 0.2s ease-in-out;
      border-radius: 20px;
    }
    .mais-filtros-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-left: 1em;
    }
    .mais-filtros-button {
      width: 100%;
      margin: 0 !important;
      font-size: 3rem;
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.light};
    }

    .divtwo {
      background-color: ${({ theme }) => theme.primary};
      color: black;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border-radius: 0;
      align-items: center;

      .numero {
        @media (max-width: 900px) {
          width: 70% !important;
        }
      }

      .numero::placeholder {
        color: gray;
        display: flex;
        font-weight: 400;
        text-align: center;
        font-size: 1.5em;
        justify-content: center;
      }
    }

    .filter-container {
      height: 100%;
      background-color: #004aa0;
      width: 100%;
      border-radius: 0 0 12px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .divtwo div {
      @media (min-width: 900px) {
        flex: 1 0 25%;
      }
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      @media (max-width: 900px) {
        width: 100%;
      }
    }

    .orgao-input .MuiInputBase-input::placeholder {
      font-family: Montserrat;
      font-weight: 500;
    }

    .divtwo button {
      width: 210px;
      padding: 10px;
      font-size: 1.7rem;
      font-weight: normal;
      border-radius: 5px;
      justify-content: center;
      @media (max-width: 900px) {
        width: 70%;
      }
    }

    @media (max-width: 900px) {
      .divtwo button {
        width: 70%;
      }
    }

    .divthree {
      color: black;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .divtwo + .divthree {
      margin-top: 10px; /* Adjusted position when divtwo is visible */
    }

    .title {
      color: black;
      font-size: 1.6rem;
      font-weight: bold;
    }

    aside.filters-bar {
      padding: 16px 24px;
      background-color: ${({ theme }) => theme.primary};
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-radius: 12px;

      form {
        padding-top: 18px;
        width: 100%;
        display: grid;
        gap: 18px;

        button.filter {
          margin-top: 6px;
          width: 100%;
          display: grid;
          place-items: center;
          background-color: ${({ theme }) => theme.light};
          color: ${({ theme }) => theme.primary};
          font-size: 1.6rem;
          font-weight: bold;
          padding: 12px 24px;
          border-radius: 5px;
          transition: filter 0.2s ease-in-out;

          &:hover {
            filter: brightness(0.8);
          }
        }
      }
    }

    aside.editais-participantes {
      margin-top: 32px;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }

  @media (max-width: 768px) {
    gap: 24px;
    padding: 16px;

    section {
      header {
        gap: 16px;
        flex-direction: column;
        align-items: flex-start;

        div.titles {
          h4 {
            font-size: 2rem;
            line-height: 2.8rem;
          }

          h6 {
            font-size: 1.4rem;
            line-height: 2rem;
          }
        }

        div.newsletter {
          width: 100%;

          button.register {
            width: 100%;
          }
        }
      }
    }

    main {
      gap: 16px;

      .divone {
        flex-direction: column;
        align-items: center;
      }

      input {
        width: 100%;
        margin: 1em 0;
      }

      .filter-button {
        width: 100%;
      }

      .mais-filtros-button {
        width: 100%;
      }

      .divtwo {
        flex-direction: column;

        div {
          @media (min-width: 900px) {
            flex: 1 0 100%;
          }
        }

        button {
          width: 100%;
        }
      }

      aside.filters-bar form {
        gap: 12px;

        button.filter {
          width: 100%;
        }
      }

      aside.editais-participantes {
        grid-template-columns: 1fr;
      }
    }
  }

  @media (max-width: 600px) {
    gap: 16px;
    padding: 8px;
    justify-content: center;

    section {
      header {
        gap: 8px;
        flex-direction: column;
        align-items: flex-start;

        div.titles {
          h4 {
            font-size: 1.6rem;
            line-height: 2.4rem;
          }

          h6 {
            font-size: 1.2rem;
            line-height: 1.8rem;
          }
        }

        div.newsletter {
          width: 100%;

          button.register {
            width: 100%;
            font-size: 1.4rem;
            padding: 10px 20px;
          }
        }
      }
    }

    main {
      gap: 12px;

      .divone {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        min-width: 95%;
      }

      input {
        align-items: center;
        width: 95% !important;
        margin: 0 !important;
      }

      .filter-button {
        width: 30%;
        font-size: 1.4rem;
      }

      .mais-filtros-button {
        width: 30%;
        font-size: 1.4rem;
      }

      .divtwo {
        flex-direction: column;

        div {
          @media (min-width: 900px) {
            flex: 1 0 100%;
          }
        }

        button {
          width: 70%;
          font-size: 1.4rem;
          padding: 0.4em 0;
        }
      }

      aside.filters-bar form {
        gap: 8px;

        button.filter {
          width: 100%;
          font-size: 1.4rem;
          padding: 10px 20px;
        }
      }

      aside.editais-participantes {
        grid-template-columns: 1fr;
      }
    }
  }
`;

export const EmptyContainer = styled.div`
  color: #043063;
`;
