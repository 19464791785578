import * as RdxSelect from '@radix-ui/react-select';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import {
  RdxContent,
  RdxItem,
  RdxItemText,
  RdxTrigger,
  SelectContainer,
} from '@components/Select/styles';
import { useId } from 'react';
import { Option } from '@pages/Home';

type SelectProps = {
  label: string;
  options: Option[];
  placeholder: string;
  value?: string;
  onChange?: (value: string) => void;
};

export const Select = ({
  label,
  options,
  onChange,
  value,
  placeholder,
}: SelectProps) => {
  const selectId = useId();

  const handleSelect = (value: string) => {
    onChange?.(value);
  };

  return (
    <SelectContainer>
      <label htmlFor={selectId}>{label}</label>

      <RdxSelect.Root value={value} onValueChange={handleSelect}>
        <RdxTrigger className="SelectTrigger">
          <RdxSelect.Value
            className="radix-select__value"
            placeholder={placeholder}
          />
          <RdxSelect.Icon className="SelectIcon">
            <ChevronDownIcon />
          </RdxSelect.Icon>
        </RdxTrigger>

        <RdxSelect.Portal>
          <RdxContent position="popper" className="SelectContent">
            <RdxSelect.ScrollUpButton className="SelectScrollButton">
              <ChevronUpIcon color="#000" />
            </RdxSelect.ScrollUpButton>

            <RdxSelect.Viewport className="SelectViewport">
              {options.map((option) => (
                <RdxItem key={option.value} value={option.value}>
                  <RdxItemText>{option.label}</RdxItemText>
                </RdxItem>
              ))}
            </RdxSelect.Viewport>

            <RdxSelect.ScrollDownButton className="SelectScrollButton">
              <ChevronDownIcon color="#000" />
            </RdxSelect.ScrollDownButton>
          </RdxContent>
        </RdxSelect.Portal>
      </RdxSelect.Root>
    </SelectContainer>
  );
};
