import styled from 'styled-components';
import * as RdxSelect from '@radix-ui/react-select';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  label {
    font-weight: bold;
    color: ${({ theme }) => theme.light};
    font-size: 2rem;
    margin-left: 4px;
  }

  span {
    max-width: 160px;
    overflow: hidden;
    font-size: 1.2em;
  }
`;

export const RdxTrigger = styled(RdxSelect.Trigger)`
  all: unset;
  font-size: 2.4rem;
  background: white;
  border-radius: 21px !important;
  color: gray;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &[data-placeholder] {
    color: gray;
  }
`;

export const RdxContent = styled(RdxSelect.Content)`
  background: ${({ theme }) => theme.light};
  max-height: 280px;
  border-radius: 5px;
  padding: 10px;
  max-width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RdxViewport = styled(RdxSelect.Viewport)`
  padding: 4px;
`;

export const RdxItem = styled(RdxSelect.Item)`
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.dark};
  transition: background-color 0.2s;
  border-radius: 5px;

  &[data-state='checked'] {
    color: ${({ theme }) => theme.light};
    background-color: ${({ theme }) => theme.primary};
  }

  &[data-highlighted] {
    outline: none;
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.light};
    border-radius: 5px;
  }
`;

export const RdxItemText = styled(RdxSelect.ItemText)``;
