export const segments = [
  { value: '0d8a2839-ee11-4f7f-8528-ed3a08b90338', label: 'Cesta básica' },
  { value: 'ce23db6d-be9c-440d-9e57-52693b4e6d62', label: 'Medicamentos' },
  {
    value: '779679bb-9a0c-4268-91b8-ac39bed8f820',
    label: 'Materiais, produtos e utensílios médico, hospitalar e laboratorial',
  },
  {
    value: '077b9b3d-3258-4a30-b221-d9248f714db5',
    label: 'Produtos de higiene, limpeza e higiene pessoal',
  },
  {
    value: '58ae9ec6-aa87-49c4-9ba4-cd05de97ba18',
    label: 'Gêneros alimentícios',
  },
  {
    value: 'a27bfd55-d0d7-4c49-b09b-08d3857f0849',
    label: 'Pontes, obras de arte, viadutos, túneis, passarelas',
  },
  {
    value: '4dcfc01f-8cb8-4116-8a34-04caa51a13b4',
    label: 'Construção, madeiras',
  },
  {
    value: '55028116-489c-461c-884c-9c75101bd537',
    label:
      'Estrutura metálica, andaimes, palco, arquibancadas, abrigos, toldos',
  },
  {
    value: 'bc0b6b15-d402-421c-a711-0980516e170b',
    label: 'Aquisição de veículos, caminhões, ônibus, ambulâncias, motos',
  },
  {
    value: 'f36c1215-fe9d-4873-85e5-28f5c1fc5f2c',
    label: 'Equipamentos e periféricos, compra, instalação e locação',
  },
  {
    value: '680c23c4-3a8f-4fb8-9cd4-ed5753f92c09',
    label: 'Serviços gráficos, impressos e edições',
  },
  { value: '1443db51-dd7b-437d-9de5-8d68eff7fd9c', label: 'Elétricos' },
  {
    value: 'e455a11d-6766-4c32-92a2-e7d0db1e321f',
    label: 'Serviços de locação de veículos, motos',
  },
  {
    value: '087ba639-8ca5-48ce-a695-a589dfd80e4c',
    label: 'Equipamentos e materiais esportivos, instrumentos musicais, artes',
  },
  {
    value: 'abd14dbf-cf53-48f6-b8ed-3e470922437a',
    label: 'Equipamentos e produtos de proteção individual',
  },
  {
    value: 'b523be29-ad7e-40ef-85bf-38db53e8b69a',
    label: 'Materiais de escritório, expediente, papéis, formulários',
  },
  { value: '7a5fed19-0634-45dd-b4b8-10a863bb8b37', label: 'Gases' },
];
