import styled from 'styled-components';

export const CardEditalContainer = styled.div`
  background: ${({ theme }) => theme.light};
  border-radius: 12px;
  margin: 40px 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;

  .big-container {
    width: 80%;
    display: grid;
    padding-bottom: 1em;
    box-sizing: border-box;

    .header-row,
    .infos.second-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      box-sizing: border-box;
      margin-top: 1em;
    }

    .titles {
      align-items: baseline !important;
      h6 {
        font-size: 1.5em !important;
      }
    }

    .titles,
    .row-info,
    .details {
      display: flex;
      align-items: center;
      padding: 0.5em;
      max-width: 359px;
      border-radius: 8px;
      background: ${({ theme }) => theme.background};
      box-sizing: border-box;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1em;

        svg {
          height: 3em;
          width: 3em;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: ${({ theme }) => theme.secondary};

        label {
          color: ${({ theme }) => theme.secondary};
          font-size: 1.5rem;
          font-weight: bold;
          white-space: nowrap;
        }

        span {
          color: ${({ theme }) => theme.secondary};
          font-size: 1.3rem;
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.primary};
        color: ${({ theme }) => theme.light};
        font-size: 1.4rem;
        font-weight: bold;
        height: 3em;
        width: 20.7em; /* Adjust this value to match the width of the largest element */
        border-radius: 5px;
        transition: filter 0.2s ease-in-out;

        &:hover {
          filter: brightness(0.8);
        }
      }

      .name {
        font-weight: bold;
        font-size: 1.6rem;
        color: ${({ theme }) => theme.primary};
      }

      span {
        color: ${({ theme }) => theme.secondary};
        font-size: 1.4rem;
      }
    }

    .titles {
      flex-direction: column;
      justify-content: center;

      span {
        font-weight: normal !important;
      }
    }

    .details .menu-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.secondary};
      background-color: ${({ theme }) => theme.primary};
      height: 3em;
      width: 3em;
      padding: 0.5em;
      margin-right: 1em;
      border-radius: 8px;

      svg {
        height: 3em;
        width: 3em;
      }
    }
  }
`;
