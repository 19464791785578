export const dadosSuporteMock = {
  tipoLance: [
    {
      id: 1,
      name: 'Global do Lote',
    },
    {
      id: 2,
      name: 'Unitário para o Item',
    },
  ],
  fichaTecnica: [
    {
      id: 1,
      name: 'Não exigir de nenhum participante',
    },
    {
      id: 2,
      name: 'Para todos os participantes no cadastro da proposta',
    },
    {
      id: 3,
      name: 'Somente do vencedor na fase de aceitação',
    },
  ],
  configuracaoParticipacao: [
    {
      id: 1,
      name: 'Ampla participação',
    },
    {
      id: 2,
      name: 'Cota diferenciada de 25% para ME-EPP',
    },
    {
      id: 3,
      name: 'Exclusiva participação ME-EPP',
    },
  ],
  variacaoMinimaLances: [
    {
      id: 1,
      name: 'Em reais',
    },
    {
      id: 2,
      name: 'Em percentual',
    },
  ],
  unidadeMedida: [
    {
      descricao: 'Adesivo',
      sigla: 'ADE',
      codigo: 111,
      id: 1,
      uniqueId: 'e4c8c7fe-72db-4dce-815b-4ae15d6b5ff7',
    },
    {
      descricao: 'Amarrado',
      sigla: 'AMA',
      codigo: 112,
      id: 2,
      uniqueId: '0cbf6200-aa99-421c-b702-948f5283b709',
    },
    {
      descricao: 'Ampola',
      sigla: 'AMP',
      codigo: 113,
      id: 3,
      uniqueId: '412f1aa6-dff3-449d-95c0-e67ecdcd0020',
    },
    {
      descricao: 'Ampola',
      sigla: 'Am',
      codigo: 31,
      id: 4,
      uniqueId: '4b54125b-0366-461f-ad5b-b0dbb2331eaf',
    },
    {
      descricao: 'Anual',
      sigla: 'Ano',
      codigo: 77,
      id: 5,
      uniqueId: 'd4b5d6d0-a709-4fcb-a7cf-6aded402cf54',
    },
    {
      descricao: 'Arroba',
      sigla: '@',
      codigo: 11,
      id: 6,
      uniqueId: '77fcf56d-7591-46fd-b0b2-91c6f471846d',
    },
    {
      descricao: 'Balde',
      sigla: 'BD',
      codigo: 60,
      id: 7,
      uniqueId: 'ca454fac-f11b-48db-891f-e3eb53dc41f6',
    },
    {
      descricao: 'Baldes',
      sigla: 'BAL',
      codigo: 114,
      id: 8,
      uniqueId: '519f822b-c370-4cff-b332-42ed8b3241ea',
    },
    {
      descricao: 'Bandeja',
      sigla: 'BAN',
      codigo: 115,
      id: 9,
      uniqueId: 'd6fea95e-4291-4466-bd52-4fde9e7bae3f',
    },
    {
      descricao: 'Bandeja',
      sigla: 'Ban',
      codigo: 71,
      id: 10,
      uniqueId: '0a38085d-69da-4dc2-b7ab-d26b6f6f1911',
    },
    {
      descricao: 'Barra',
      sigla: 'Br',
      codigo: 62,
      id: 11,
      uniqueId: '18b6e9cd-0ea5-41da-9496-a2153f59ed44',
    },
    {
      descricao: 'Barra',
      sigla: 'BAR',
      codigo: 116,
      id: 12,
      uniqueId: 'd6a8aad0-a72b-41d9-8dee-46f7bc27dbff',
    },
    {
      descricao: 'Barrica',
      sigla: 'BRC',
      codigo: 125,
      id: 13,
      uniqueId: 'a745d91b-3b03-4779-a793-13dffcf2ec15',
    },
    {
      descricao: 'Bastao',
      sigla: 'BAS',
      codigo: 117,
      id: 14,
      uniqueId: '3783d95f-163f-4bfa-ab24-f78e7d12c150',
    },
    {
      descricao: 'Bisnaga',
      sigla: 'BIS',
      codigo: 118,
      id: 15,
      uniqueId: '072f623e-9194-4ea4-8ba8-d0a875d7aff6',
    },
    {
      descricao: 'Bisnaga',
      sigla: 'Bg',
      codigo: 24,
      id: 16,
      uniqueId: 'ec9f17b1-9bb6-439a-ac3c-22526030d937',
    },
    {
      descricao: 'Bloco',
      sigla: 'Bl',
      codigo: 41,
      id: 17,
      uniqueId: '18fccc35-0e46-4468-9a58-4b984ba395ac',
    },
    {
      descricao: 'Blocos',
      sigla: 'BLO',
      codigo: 119,
      id: 18,
      uniqueId: '7ae6c887-7302-43a6-9b5f-52d7c536ac0c',
    },
    {
      descricao: 'Bobina',
      sigla: 'BOB',
      codigo: 121,
      id: 19,
      uniqueId: '74d7d850-89a8-4e73-b793-73caaf43a915',
    },
    {
      descricao: 'Bobina',
      sigla: 'bb',
      codigo: 39,
      id: 20,
      uniqueId: '1fbf7cde-aabc-4d4d-a0ea-9dcf3d2058cf',
    },
    {
      descricao: 'Bola',
      sigla: 'BOL',
      codigo: 122,
      id: 21,
      uniqueId: '0c3afbd6-cffa-4964-9708-ce167c54701a',
    },
    {
      descricao: 'Bolsa',
      sigla: 'BLS',
      codigo: 120,
      id: 22,
      uniqueId: '5c2d3cde-bf27-485f-87c5-f3ae06d58bb1',
    },
    {
      descricao: 'Bolsa',
      sigla: 'Bs',
      codigo: 52,
      id: 23,
      uniqueId: 'd0c2ba2f-b02e-4608-8fa7-9d70d3b7fa5a',
    },
    {
      descricao: 'Bombona',
      sigla: 'BOM',
      codigo: 123,
      id: 24,
      uniqueId: 'd3a49893-6568-4ab3-a83d-2e3d6f5dc259',
    },
    {
      descricao: 'Botijao',
      sigla: 'BOT',
      codigo: 124,
      id: 25,
      uniqueId: 'ed74f670-3675-447b-9f31-6c720f4ceb51',
    },
    {
      descricao: 'Caderno',
      sigla: 'CAD',
      codigo: 126,
      id: 26,
      uniqueId: '406ac4b2-2ab0-43b1-a459-2d3972c5aa7c',
    },
    {
      descricao: 'Caixa',
      sigla: 'Cx',
      codigo: 6,
      id: 27,
      uniqueId: 'c89745d5-c101-4aa9-bfc3-4ffa128869ea',
    },
    {
      descricao: 'Caneta',
      sigla: 'CN',
      codigo: 51,
      id: 28,
      uniqueId: 'f674fcf8-02ef-48fe-825e-c4f786045b46',
    },
    {
      descricao: 'Caneta',
      sigla: 'CAN',
      codigo: 127,
      id: 29,
      uniqueId: 'f5835f59-d556-4f43-8bc6-3ff66bd6c0b5',
    },
    {
      descricao: 'Capsula',
      sigla: 'CAP',
      codigo: 128,
      id: 30,
      uniqueId: '6067727c-cebf-48f2-832a-15be1f087c12',
    },
    {
      descricao: 'Capsula',
      sigla: 'Cp',
      codigo: 25,
      id: 31,
      uniqueId: 'd6b4f934-5212-4f69-bfe2-b1fe44a98710',
    },
    {
      descricao: 'Carga',
      sigla: 'CGA',
      codigo: 131,
      id: 32,
      uniqueId: '24068674-eb0d-4ef8-ad8a-d293e98601bd',
    },
    {
      descricao: 'Carpule',
      sigla: 'CRL',
      codigo: 141,
      id: 33,
      uniqueId: 'a4f70f5b-976f-4c43-b9c9-1d11fbd22e8c',
    },
    {
      descricao: 'Carretel',
      sigla: 'CRL',
      codigo: 140,
      id: 34,
      uniqueId: '3a2de6bf-39fb-4e57-a4e1-37fce6464947',
    },
    {
      descricao: 'Cartao',
      sigla: 'CAR',
      codigo: 129,
      id: 35,
      uniqueId: 'a0e07abf-6e61-4c0a-b2aa-a466b95e1ca9',
    },
    {
      descricao: 'Carteira',
      sigla: 'CRT',
      codigo: 144,
      id: 36,
      uniqueId: '7299d7b1-ed86-431e-98e3-47fa988834af',
    },
    {
      descricao: 'Cartela',
      sigla: 'Ctl',
      codigo: 9,
      id: 37,
      uniqueId: 'dedc33a6-7cc7-48f7-9025-8e80d6787909',
    },
    {
      descricao: 'Cent.Cubic',
      sigla: 'CM3',
      codigo: 135,
      id: 38,
      uniqueId: '1c3b6711-73ed-480a-96cd-c45ec18570d4',
    },
    {
      descricao: 'Centigrama',
      sigla: 'CG',
      codigo: 85,
      id: 40,
      uniqueId: 'eb39af3d-03f3-4abc-bad9-e06ff892eba9',
    },
    {
      descricao: 'Centímetro',
      sigla: 'CM',
      codigo: 65,
      id: 41,
      uniqueId: 'bd56aa0a-6374-46b5-ae17-c3c717814971',
    },
    {
      descricao: 'centímetroporcoluna',
      sigla: 'CMC',
      codigo: 218,
      id: 42,
      uniqueId: '1889da81-5147-4439-a7ba-d7c0fbdff10e',
    },
    {
      descricao: 'Cento',
      sigla: 'ct',
      codigo: 76,
      id: 43,
      uniqueId: 'b6360c2a-7921-4125-9eff-e0e9b0fbeca8',
    },
    {
      descricao: 'Cento',
      sigla: 'CEN',
      codigo: 130,
      id: 44,
      uniqueId: '16ed1079-c6ce-432b-9ce2-0e3d4cdd1ea0',
    },
    {
      descricao: 'Cent.Quadr',
      sigla: 'CM2',
      codigo: 134,
      id: 39,
      uniqueId: '834ff24b-f9b0-410b-a60b-eaf35452fb9c',
    },
    {
      descricao: 'Centrimetro',
      sigla: 'CMS',
      codigo: 136,
      id: 45,
      uniqueId: '107faff1-9b92-4d7b-ba17-f50939d705c4',
    },
    {
      descricao: 'Cilindro',
      sigla: 'CIL',
      codigo: 132,
      id: 46,
      uniqueId: '751069d8-ee19-4811-8b19-b4bb08e9b1d3',
    },
    {
      descricao: 'Cinta',
      sigla: 'CIN',
      codigo: 133,
      id: 47,
      uniqueId: '49d21814-bd59-4483-83b8-209024d47ff1',
    },
    {
      descricao: 'Colecao',
      sigla: 'COL',
      codigo: 137,
      id: 48,
      uniqueId: '0fc97e50-0c65-4cb7-b54e-56bd4865b630',
    },
    {
      descricao: 'Comprimido',
      sigla: 'CP',
      codigo: 27,
      id: 50,
      uniqueId: '6fed9070-b9a5-4bcb-8f7b-791d962cc240',
    },
    {
      descricao: 'Comprimido',
      sigla: 'COM',
      codigo: 138,
      id: 49,
      uniqueId: '2c71d441-b420-4f60-9f69-5701da1590ab',
    },
    {
      descricao: 'Conjunto',
      sigla: 'Cj',
      codigo: 35,
      id: 51,
      uniqueId: 'd85a9407-29b4-4b80-b861-350c8b8252e5',
    },
    {
      descricao: 'Cópia',
      sigla: 'Cpi',
      codigo: 225,
      id: 223,
      uniqueId: '39dea467-9c1c-4599-9a37-d04efaeae29e',
    },
    {
      descricao: 'Copo',
      sigla: 'COP',
      codigo: 139,
      id: 52,
      uniqueId: '5c26add7-c648-4e40-880d-e1cb74cd1a6a',
    },
    {
      descricao: 'CustoHorárioProdutivo',
      sigla: 'CHP',
      codigo: 210,
      id: 53,
      uniqueId: 'a376f153-f8d7-498d-b464-63b674341624',
    },
    {
      descricao: 'Decagrama',
      sigla: 'DAG',
      codigo: 145,
      id: 56,
      uniqueId: '121fa779-f5a7-4c3f-8f10-74a3cb922697',
    },
    {
      descricao: 'Decalitro',
      sigla: 'DAL',
      codigo: 146,
      id: 57,
      uniqueId: 'b186a89a-0195-424b-9b76-ca5bfc5afe24',
    },
    {
      descricao: 'Decametro',
      sigla: 'DAM',
      codigo: 147,
      id: 58,
      uniqueId: '71538229-59af-4686-8564-83cf511aedc7',
    },
    {
      descricao: 'Dec.Cubico',
      sigla: 'DC3',
      codigo: 149,
      id: 54,
      uniqueId: '23a1e11f-bb0a-4636-b5ee-aa9976f61bfd',
    },
    {
      descricao: 'Decigrama',
      sigla: 'DEG',
      codigo: 150,
      id: 59,
      uniqueId: 'ed94266a-0db1-4ab9-93e1-0016ac68a0be',
    },
    {
      descricao: 'Decilitro',
      sigla: 'DEL',
      codigo: 151,
      id: 60,
      uniqueId: '8759bd6b-57a9-40b1-9a08-9db57829f3f1',
    },
    {
      descricao: 'Decimetro',
      sigla: 'DEM',
      codigo: 152,
      id: 61,
      uniqueId: '7568d6cc-8cf1-4801-a62a-fd136fc59515',
    },
    {
      descricao: 'decímetrocúbicoeolitro',
      sigla: 'DM3',
      codigo: 209,
      id: 62,
      uniqueId: 'e3f32fac-9670-4549-b868-dcccc6b9fe1c',
    },
    {
      descricao: 'DEC.Quadra',
      sigla: 'DC2',
      codigo: 148,
      id: 55,
      uniqueId: 'fa02b6c1-a3ba-4da0-9cb9-a317bc079261',
    },
    {
      descricao: 'Dezena',
      sigla: 'Dz',
      codigo: 12,
      id: 64,
      uniqueId: '93ae5566-de58-47bb-955a-abb0649ba765',
    },
    {
      descricao: 'Dezena',
      sigla: 'DEZ',
      codigo: 153,
      id: 63,
      uniqueId: '11f71260-f21a-43b6-bfc3-ac4d34a2a0ab',
    },
    {
      descricao: 'Dia',
      sigla: 'DI',
      codigo: 70,
      id: 65,
      uniqueId: '347ffd69-6b74-48f3-afd5-184c772be8a3',
    },
    {
      descricao: 'Diaria',
      sigla: 'DI',
      codigo: 69,
      id: 66,
      uniqueId: 'd04b10a1-19c5-4f4e-8c42-4ea348ae7ff5',
    },
    {
      descricao: 'dose',
      sigla: 'dos',
      codigo: 216,
      id: 67,
      uniqueId: '9f6fd2bd-90e3-4e67-8c7b-c0a9ac9f5e7a',
    },
    {
      descricao: 'Dragea',
      sigla: 'DG',
      codigo: 86,
      id: 68,
      uniqueId: '6c185901-0359-4841-ac13-1fdc85dd7ff9',
    },
    {
      descricao: 'Drágea',
      sigla: 'Dr',
      codigo: 30,
      id: 69,
      uniqueId: 'b053d624-0c0a-4101-9f59-b9d7a0ba5641',
    },
    {
      descricao: 'Duzia',
      sigla: 'Dz',
      codigo: 33,
      id: 70,
      uniqueId: 'ebf9a515-6f3b-4c65-ab69-1f81977f919c',
    },
    {
      descricao: 'Embalagem',
      sigla: 'EB',
      codigo: 49,
      id: 71,
      uniqueId: '8ce950a3-9457-4874-9922-cf3d6e006387',
    },
    {
      descricao: 'Embgcom100unidades',
      sigla: 'Emb',
      codigo: 81,
      id: 72,
      uniqueId: '7c9fa236-698f-4819-91d2-3f56152d1fef',
    },
    {
      descricao: 'Engradado',
      sigla: 'ENG',
      codigo: 154,
      id: 73,
      uniqueId: 'b5bc8fb0-8c34-4d63-9d76-a44477ec4c74',
    },
    {
      descricao: 'Envelope',
      sigla: 'Ev',
      codigo: 23,
      id: 75,
      uniqueId: 'a54f5edf-f33e-4952-8bc8-c5e1ca057ecf',
    },
    {
      descricao: 'Envelope',
      sigla: 'ENV',
      codigo: 155,
      id: 74,
      uniqueId: 'cb1602bb-db89-4f0c-b737-9c01e424a5da',
    },
    {
      descricao: 'Estojo',
      sigla: 'EST',
      codigo: 156,
      id: 77,
      uniqueId: '89f7b1f3-7c49-45d0-bc36-fc117e3dfa51',
    },
    {
      descricao: 'Estojo',
      sigla: 'EST',
      codigo: 72,
      id: 76,
      uniqueId: '7a029aa5-156d-4aab-9b81-32e61a9dd1fb',
    },
    {
      descricao: 'Estola',
      sigla: 'ETL',
      codigo: 157,
      id: 78,
      uniqueId: '82582520-6842-450d-846d-92dd2d346533',
    },
    {
      descricao: 'Evento',
      sigla: 'EV',
      codigo: 82,
      id: 79,
      uniqueId: '82f41a4d-2afc-4199-a1ca-b1d98040fb97',
    },
    {
      descricao: 'Fardo',
      sigla: 'Far',
      codigo: 18,
      id: 80,
      uniqueId: '316582dd-ef29-43f3-8846-bfb15c95a59b',
    },
    {
      descricao: 'Fardo',
      sigla: 'FDO',
      codigo: 159,
      id: 81,
      uniqueId: '9681ae8b-ee6a-46b8-8e83-802a9657ba95',
    },
    {
      descricao: 'Ficha',
      sigla: 'FCH',
      codigo: 158,
      id: 82,
      uniqueId: 'c36097df-77bd-4ca9-8f2d-d9b114e86a23',
    },
    {
      descricao: 'Fita',
      sigla: 'FIT',
      codigo: 160,
      id: 83,
      uniqueId: '2f6f3d9b-4dd3-4956-b01f-369273d6588f',
    },
    {
      descricao: 'Flaconete',
      sigla: 'Fla',
      codigo: 48,
      id: 85,
      uniqueId: '8b79bf8b-6fe2-48b4-8829-d827f06409ef',
    },
    {
      descricao: 'Flaconete',
      sigla: 'FLA',
      codigo: 161,
      id: 84,
      uniqueId: 'c5ca30dd-e16f-4892-a2c4-24cac3d90929',
    },
    {
      descricao: 'Folha',
      sigla: 'FL',
      codigo: 46,
      id: 86,
      uniqueId: 'f09196c4-02b0-44a5-89ab-c38bcdb9ba10',
    },
    {
      descricao: 'Folhas',
      sigla: 'FLS',
      codigo: 162,
      id: 87,
      uniqueId: '9624a6d3-8aa8-40d7-be08-8cf9f393f315',
    },
    {
      descricao: 'Fração',
      sigla: 'FC',
      codigo: 67,
      id: 88,
      uniqueId: '9477a709-e76e-42c5-b3b5-20eecd22e904',
    },
    {
      descricao: 'Frasco',
      sigla: 'Fr',
      codigo: 14,
      id: 89,
      uniqueId: 'e3b8d57d-1495-4e8b-ba98-d39ecc596932',
    },
    {
      descricao: 'Frasco/Ampola',
      sigla: 'F/A',
      codigo: 36,
      id: 90,
      uniqueId: '06220fcd-5a8c-4de9-ad99-57a0f3910b94',
    },
    {
      descricao: 'Frascos',
      sigla: 'FRS',
      codigo: 163,
      id: 91,
      uniqueId: '2369998d-1275-49cc-9dc0-bb631aa02e3b',
    },
    {
      descricao: 'GALAO',
      sigla: 'GAL',
      codigo: 164,
      id: 92,
      uniqueId: '80ba5002-612d-4f85-bc01-39f691d74360',
    },
    {
      descricao: 'Galão',
      sigla: 'Gal',
      codigo: 15,
      id: 93,
      uniqueId: '07934d15-5206-4dfa-8994-5c6deb952ad5',
    },
    {
      descricao: 'Garrafa',
      sigla: 'Gf',
      codigo: 59,
      id: 94,
      uniqueId: '29ba1cb5-64fa-4111-a3d8-86b8ad7b6382',
    },
    {
      descricao: 'GARRAFAO',
      sigla: 'GFO',
      codigo: 165,
      id: 95,
      uniqueId: '0361a2b0-aad6-44ea-a812-91ec08d53ef3',
    },
    {
      descricao: 'Grama',
      sigla: 'GR',
      codigo: 45,
      id: 96,
      uniqueId: 'cf11316f-fcaa-45a5-a8a5-11fe3b932d5e',
    },
    {
      descricao: 'Gramas',
      sigla: 'GRS',
      codigo: 167,
      id: 97,
      uniqueId: '0ac5dcb7-acae-47b2-8eb1-a0ae3440b764',
    },
    {
      descricao: 'Grosa',
      sigla: 'GRO',
      codigo: 166,
      id: 98,
      uniqueId: '50fb00d7-5cd2-4c77-ba9a-fb12d3bf7c30',
    },
    {
      descricao: 'Hectare',
      sigla: 'HC',
      codigo: 68,
      id: 99,
      uniqueId: 'bafc7e81-0f30-498b-a6ae-22425cc0f16d',
    },
    {
      descricao: 'Hectolitro',
      sigla: 'HL',
      codigo: 88,
      id: 100,
      uniqueId: '6d19bfd4-b3d6-44d6-8612-7a320e080673',
    },
    {
      descricao: 'Hectometro',
      sigla: 'HM',
      codigo: 89,
      id: 101,
      uniqueId: 'abc76c62-728a-4847-bd1c-d5194b570228',
    },
    {
      descricao: 'Hora',
      sigla: 'HOR',
      codigo: 168,
      id: 103,
      uniqueId: '47eec03f-8823-4892-8945-c41e49842fcf',
    },
    {
      descricao: 'Hora',
      sigla: 'HR',
      codigo: 56,
      id: 102,
      uniqueId: '791209eb-c78e-4609-a7cc-39e667b5db05',
    },
    {
      descricao: 'HoraMaquina',
      sigla: 'HM',
      codigo: 74,
      id: 104,
      uniqueId: '6fc2c3c3-3a83-4950-9c15-fb018141b54d',
    },
    {
      descricao: 'Injetavel',
      sigla: 'Inj',
      codigo: 38,
      id: 105,
      uniqueId: '5c67ceb8-99fe-42b7-878b-4a7d6d2dbcba',
    },
    {
      descricao: 'Jarda',
      sigla: 'JAR',
      codigo: 169,
      id: 106,
      uniqueId: 'db413972-e6b7-4ea5-bd55-8060b6dd0139',
    },
    {
      descricao: 'JardaCubi',
      sigla: 'JD3',
      codigo: 171,
      id: 107,
      uniqueId: '940e46f8-8063-41a9-8bca-8d948748ccca',
    },
    {
      descricao: 'JardaQuad',
      sigla: 'JD2',
      codigo: 170,
      id: 108,
      uniqueId: '7480d1c7-e7bc-4fae-8921-794efb4d7659',
    },
    {
      descricao: 'Jogo',
      sigla: 'JG',
      codigo: 90,
      id: 109,
      uniqueId: '7a884dbe-2d0a-4641-be9f-e2002ce9eb3e',
    },
    {
      descricao: 'jogos',
      sigla: 'jg',
      codigo: 22,
      id: 110,
      uniqueId: 'b36cd953-60d6-4c55-88f3-7e7d27f6fd7b',
    },
    {
      descricao: 'Kilograma',
      sigla: 'KG',
      codigo: 91,
      id: 111,
      uniqueId: 'e99c280f-dcd6-4a4f-a4ea-cb3b5045b516',
    },
    {
      descricao: 'Kilolitro',
      sigla: 'KL',
      codigo: 92,
      id: 112,
      uniqueId: '513065ec-b3d8-47ca-8a2a-b82ae763eab1',
    },
    {
      descricao: 'Kilometro',
      sigla: 'KM',
      codigo: 93,
      id: 113,
      uniqueId: 'e547b386-a338-4d10-804a-1e2b3c105427',
    },
    {
      descricao: 'Kit',
      sigla: 'kt',
      codigo: 21,
      id: 115,
      uniqueId: 'a67945fa-393a-4a20-9783-e4d7780361a2',
    },
    {
      descricao: 'KIT',
      sigla: 'KIT',
      codigo: 172,
      id: 114,
      uniqueId: 'ff3c4a2e-a844-4c13-90dc-3fb6a4bca2eb',
    },
    {
      descricao: 'KMRodado',
      sigla: 'Km',
      codigo: 75,
      id: 116,
      uniqueId: '0d7995e7-e411-4ba2-8034-a6a0f68db602',
    },
    {
      descricao: 'Lata',
      sigla: 'Lt',
      codigo: 28,
      id: 117,
      uniqueId: '36342ed8-a506-4ff6-acc9-cd8b7c449352',
    },
    {
      descricao: 'LATAS',
      sigla: 'LTA',
      codigo: 174,
      id: 118,
      uniqueId: '812adc53-d2e3-4c35-8ad5-679ac557ee4a',
    },
    {
      descricao: 'Libra',
      sigla: 'LB',
      codigo: 94,
      id: 119,
      uniqueId: '60b5935f-7257-482c-b6e8-c1bd009be0b1',
    },
    {
      descricao: 'Lingote',
      sigla: 'LIN',
      codigo: 173,
      id: 120,
      uniqueId: '8fc7d5c8-ede3-4461-b35f-f073fc76f49f',
    },
    {
      descricao: 'Litro',
      sigla: 'L',
      codigo: 1,
      id: 122,
      uniqueId: '883643f0-a6bc-4b80-a8d7-30b54b4e8518',
    },
    {
      descricao: 'Litro',
      sigla: 'LT',
      codigo: 95,
      id: 121,
      uniqueId: 'f126326b-aa10-432b-80a1-e19d1ed60296',
    },
    {
      descricao: 'Livro',
      sigla: 'LVR',
      codigo: 175,
      id: 123,
      uniqueId: 'b13219fe-a04e-49f6-ba00-61f1bb6c0006',
    },
    {
      descricao: 'Lugar/Dia',
      sigla: 'LgD',
      codigo: 230,
      id: 217,
      uniqueId: 'dfd7ecb5-bbe3-463f-8cf3-ea27d5fa8b9d',
    },
    {
      descricao: 'Maço',
      sigla: 'MC',
      codigo: 44,
      id: 124,
      uniqueId: '10e696e2-6d70-4969-996c-14daee4b3fe5',
    },
    {
      descricao: 'Macos',
      sigla: 'MCS',
      codigo: 176,
      id: 125,
      uniqueId: '9ac03b33-dec4-4a38-a784-53bda97fc6e5',
    },
    {
      descricao: 'Meada',
      sigla: 'ME',
      codigo: 98,
      id: 126,
      uniqueId: '8649d899-5632-4b8d-bbff-682b2d495585',
    },
    {
      descricao: 'Meadas',
      sigla: 'MEA',
      codigo: 177,
      id: 127,
      uniqueId: 'fe266d6a-c7f6-4dd2-ab18-e060ade6bc6b',
    },
    {
      descricao: 'Megabyte',
      sigla: 'MB',
      codigo: 234,
      id: 224,
      uniqueId: '2f5fdf1b-4f36-4839-a79f-3bd7466ed189',
    },
    {
      descricao: 'Megawatt-Hora',
      sigla: 'Mwh',
      codigo: 223,
      id: 221,
      uniqueId: 'd532c38f-9d60-438e-ab6e-1021e222acdb',
    },
    {
      descricao: 'Mês',
      sigla: 'Mes',
      codigo: 34,
      id: 128,
      uniqueId: '259e4ff9-25a7-4794-9bde-d38d8dd2bfee',
    },
    {
      descricao: 'Metro',
      sigla: 'M',
      codigo: 2,
      id: 129,
      uniqueId: 'd2035615-b8ba-42e0-8c1a-ac0e7104daf1',
    },
    {
      descricao: 'MetroCorrido',
      sigla: 'MC',
      codigo: 66,
      id: 130,
      uniqueId: '39fb60e8-27af-4c60-af19-27dc005a8146',
    },
    {
      descricao: 'MetroCub',
      sigla: 'M3',
      codigo: 97,
      id: 131,
      uniqueId: 'e3a8fa1e-5106-48c3-8316-b66cbe06e21b',
    },
    {
      descricao: 'MetroCubico',
      sigla: 'M3',
      codigo: 73,
      id: 132,
      uniqueId: 'a8ded8ec-72ee-4758-b3ca-961584a9ceef',
    },
    {
      descricao: 'MetroCúbico',
      sigla: 'M3',
      codigo: 53,
      id: 133,
      uniqueId: '3212dd94-dee2-4f68-8194-af5472591e23',
    },
    {
      descricao: 'MetroCubicoporKilometro',
      sigla: 'MKM',
      codigo: 211,
      id: 134,
      uniqueId: '9107cd3a-2f28-41e9-a906-2e907e2309af',
    },
    {
      descricao: 'MetroCubicoporMês',
      sigla: 'MMS',
      codigo: 212,
      id: 135,
      uniqueId: '68c80713-5ad9-4584-b29d-58897780836c',
    },
    {
      descricao: 'MetroLinear',
      sigla: 'ML',
      codigo: 63,
      id: 136,
      uniqueId: '9e1e9199-1e58-4f87-8772-37be7bdf1055',
    },
    {
      descricao: 'Metro Linear/Dia',
      sigla: 'MLD',
      codigo: 230,
      id: 219,
      uniqueId: '90e4b9d9-8ff7-4b0b-b3ce-9709d938ed92',
    },
    {
      descricao: 'MetroQua',
      sigla: 'M2',
      codigo: 96,
      id: 137,
      uniqueId: 'd68ea7e4-593b-45da-ac45-0ed2ce19241d',
    },
    {
      descricao: 'MetroQuadrado',
      sigla: 'Mt2',
      codigo: 47,
      id: 138,
      uniqueId: 'fb715b9b-529a-4014-8d5a-48108fd7bbd2',
    },
    {
      descricao: 'Metro Quadrado/Dia',
      sigla: 'M2D',
      codigo: 231,
      id: 218,
      uniqueId: '40cd036b-c9a6-4deb-9edb-a6484bcd2f30',
    },
    {
      descricao: 'metrosestéreos',
      sigla: 'ME',
      codigo: 61,
      id: 139,
      uniqueId: '49c03c5e-58f6-452c-af02-6e32f6374f21',
    },
    {
      descricao: 'milheiro',
      sigla: 'ml',
      codigo: 20,
      id: 141,
      uniqueId: '13c99396-9a72-4b99-bc3d-7a85a32feb43',
    },
    {
      descricao: 'Milheiro',
      sigla: 'MLH',
      codigo: 178,
      id: 140,
      uniqueId: '1f4fbedf-57ca-4ca5-928b-cb2a65824be6',
    },
    {
      descricao: 'Miligrama',
      sigla: 'MG',
      codigo: 99,
      id: 142,
      uniqueId: '878f7a98-a865-4ae2-ac74-43181e421227',
    },
    {
      descricao: 'Mililitro',
      sigla: 'Ml',
      codigo: 58,
      id: 143,
      uniqueId: 'a8b89c26-19c2-4bc2-bd2e-deb423abe1e9',
    },
    {
      descricao: 'Mililitros',
      sigla: 'ML',
      codigo: 100,
      id: 144,
      uniqueId: '02eacf72-3a62-4402-86ed-714f5e0d678d',
    },
    {
      descricao: 'Milimetros',
      sigla: 'MM',
      codigo: 101,
      id: 145,
      uniqueId: 'f73438da-9ce1-467f-857f-dfc6ebc2ccbb',
    },
    {
      descricao: 'Minuto',
      sigla: 'Min',
      codigo: 83,
      id: 146,
      uniqueId: '03986823-0e14-45fa-9e25-33138b7179b9',
    },
    {
      descricao: 'Molho',
      sigla: 'MO',
      codigo: 102,
      id: 147,
      uniqueId: 'e143f269-bc73-489c-a1dd-826ae7d0f183',
    },
    {
      descricao: 'MT.LINEAR',
      sigla: 'MTL',
      codigo: 179,
      id: 148,
      uniqueId: '6678d9d8-7681-4c9f-89ce-32b510a62270',
    },
    {
      descricao: 'Muda',
      sigla: 'Mud',
      codigo: 79,
      id: 149,
      uniqueId: '3a3d173a-40cc-4808-b343-44526580a731',
    },
    {
      descricao: 'Novelo',
      sigla: 'NOV',
      codigo: 180,
      id: 150,
      uniqueId: '2b4e4e0b-a13f-4303-92b4-271bd942e16f',
    },
    {
      descricao: 'Onca',
      sigla: 'ON',
      codigo: 103,
      id: 151,
      uniqueId: '0d8d44dd-522a-4784-ab1c-36b3dbcd9dd4',
    },
    {
      descricao: 'Ovulo',
      sigla: 'OV',
      codigo: 104,
      id: 152,
      uniqueId: '91e0d714-d2b9-4376-a4e8-34a30709550c',
    },
    {
      descricao: 'Pacote',
      sigla: 'Pac',
      codigo: 17,
      id: 153,
      uniqueId: '68bfb2c8-44c7-4402-95da-0c2d7824e8c6',
    },
    {
      descricao: 'PACOTES',
      sigla: 'PCT',
      codigo: 183,
      id: 154,
      uniqueId: '3b84d13a-a9f7-4d71-aca0-3db67a104b5c',
    },
    {
      descricao: 'Par',
      sigla: 'Par',
      codigo: 13,
      id: 155,
      uniqueId: '2beb6f93-9100-4f82-ab17-02dfa05b8564',
    },
    {
      descricao: 'PARES',
      sigla: 'PAR',
      codigo: 181,
      id: 156,
      uniqueId: 'ef468c8a-0090-42ad-a4e3-24c0f5557770',
    },
    {
      descricao: 'Pastilha',
      sigla: 'PAS',
      codigo: 182,
      id: 157,
      uniqueId: 'f6298d5a-b0f2-4434-9591-8176799eb1c3',
    },
    {
      descricao: 'PE',
      sigla: 'PE',
      codigo: 106,
      id: 158,
      uniqueId: 'a7230689-d4bb-40cb-b6d8-fe0992540618',
    },
    {
      descricao: 'Peca',
      sigla: 'PC',
      codigo: 105,
      id: 161,
      uniqueId: 'a4850f6e-5036-44da-a64b-00e8835472e3',
    },
    {
      descricao: 'Peça',
      sigla: 'Pç',
      codigo: 7,
      id: 162,
      uniqueId: '85fb7f5c-066c-4f3b-b2ea-dd120f1cf689',
    },
    {
      descricao: 'PECUBICO',
      sigla: 'PE3',
      codigo: 185,
      id: 159,
      uniqueId: 'd1ab816c-6e3b-4e40-9259-ee988e293822',
    },
    {
      descricao: 'PEQUADRAD',
      sigla: 'PE2',
      codigo: 184,
      id: 160,
      uniqueId: '64ece40f-2ad4-41be-bf6e-1fed01895ef8',
    },
    {
      descricao: 'Percentual',
      sigla: '%',
      codigo: 43,
      id: 163,
      uniqueId: 'b8ee2c93-39e6-413d-8b5e-3faec8e6af77',
    },
    {
      descricao: 'Percurso',
      sigla: 'PRC',
      codigo: 230,
      id: 220,
      uniqueId: '7050877b-adf1-4304-9171-171728c1820b',
    },
    {
      descricao: 'PET',
      sigla: 'PET',
      codigo: 186,
      id: 164,
      uniqueId: '3bc02cc5-06af-4874-8356-bb5bdc5cba0c',
    },
    {
      descricao: 'Pinta',
      sigla: 'PIN',
      codigo: 187,
      id: 165,
      uniqueId: 'e0b76a50-f1a1-4e28-afdf-abb89d754228',
    },
    {
      descricao: 'Placa',
      sigla: 'PLA',
      codigo: 188,
      id: 166,
      uniqueId: '6bf23578-8a98-4418-86d6-7a00d715f712',
    },
    {
      descricao: 'POL.CUBICA',
      sigla: 'PO3',
      codigo: 190,
      id: 167,
      uniqueId: '01fbb7dd-d7c5-46c7-95b4-74d5f89eba3d',
    },
    {
      descricao: 'Polegads',
      sigla: 'POL',
      codigo: 191,
      id: 169,
      uniqueId: '52f0dd72-850d-43f7-a1c2-fef4fd6acd90',
    },
    {
      descricao: 'POL.QUADRA',
      sigla: 'PO2',
      codigo: 189,
      id: 168,
      uniqueId: 'eb66fd24-67f2-4c6f-8e4b-c918ca7312cb',
    },
    {
      descricao: 'Pontos',
      sigla: 'Pts',
      codigo: 78,
      id: 170,
      uniqueId: 'a51af71e-cb68-4405-936f-446c1a6de00b',
    },
    {
      descricao: 'Pote',
      sigla: 'Pt',
      codigo: 26,
      id: 171,
      uniqueId: 'ca96a88a-1d2e-43fb-be94-474b878d31e3',
    },
    {
      descricao: 'Pote',
      sigla: 'POT',
      codigo: 192,
      id: 172,
      uniqueId: '5b37c249-cef1-4217-83c6-7304f669c3d1',
    },
    {
      descricao: 'Projeto',
      sigla: 'pro',
      codigo: 215,
      id: 173,
      uniqueId: '197038f5-2dc4-4058-b613-456d6d0ab9c7',
    },
    {
      descricao: 'Quilograma',
      sigla: 'Kg',
      codigo: 3,
      id: 174,
      uniqueId: 'fcafdf8e-2c1f-4d6f-825f-66ffd9338921',
    },
    {
      descricao: 'Quilometro',
      sigla: 'KM',
      codigo: 54,
      id: 175,
      uniqueId: '354214ba-307c-4a93-be89-7d2cb1d580d9',
    },
    {
      descricao: 'Racao',
      sigla: 'RAC',
      codigo: 193,
      id: 176,
      uniqueId: 'ccb20abf-cab1-4ff4-8d59-3d0a2cba41b0',
    },
    {
      descricao: 'Recarga',
      sigla: 'REC',
      codigo: 222,
      id: 216,
      uniqueId: '232cc207-f657-4395-8a14-d1e00c375071',
    },
    {
      descricao: 'Refil',
      sigla: 'RF',
      codigo: 50,
      id: 178,
      uniqueId: '74b93e4a-95f2-4473-8cd8-b604f1e0d14b',
    },
    {
      descricao: 'REFIL',
      sigla: 'RFL',
      codigo: 194,
      id: 177,
      uniqueId: 'e3c29feb-0b02-40da-b90d-aafc4dae0386',
    },
    {
      descricao: 'Resma',
      sigla: 'RM',
      codigo: 107,
      id: 180,
      uniqueId: '76296b82-0a88-4988-976b-ce53fea0106e',
    },
    {
      descricao: 'Resma',
      sigla: 'Rm',
      codigo: 8,
      id: 179,
      uniqueId: 'ae0c19ea-5203-488c-92ec-10b9d37c3403',
    },
    {
      descricao: 'Rolo',
      sigla: 'Rl',
      codigo: 19,
      id: 182,
      uniqueId: 'ce2e977b-dff6-4b30-bd84-92b6dce9a05c',
    },
    {
      descricao: 'ROLO',
      sigla: 'ROL',
      codigo: 195,
      id: 181,
      uniqueId: 'aec664fd-1f70-4c89-bdee-90afba33f200',
    },
    {
      descricao: 'Saca',
      sigla: 'Sc',
      codigo: 10,
      id: 183,
      uniqueId: 'd06f577d-cf05-461e-b19a-056d5023a33c',
    },
    {
      descricao: 'Sachê',
      sigla: 'Sac',
      codigo: 29,
      id: 184,
      uniqueId: '76f636f4-1d21-4941-82db-29d7059ae587',
    },
    {
      descricao: 'Sachê',
      sigla: 'She',
      codigo: 84,
      id: 185,
      uniqueId: 'a204d84c-f7db-4089-82da-eedaacbd3b67',
    },
    {
      descricao: 'SACHES',
      sigla: 'SHE',
      codigo: 197,
      id: 186,
      uniqueId: '46b33fef-2697-495b-90a3-c017f3662ed0',
    },
    {
      descricao: 'SACOS',
      sigla: 'SCS',
      codigo: 196,
      id: 187,
      uniqueId: '0e1927a6-c03c-4935-a97b-1025561976f7',
    },
    {
      descricao: 'SACOS',
      sigla: 'SC',
      codigo: 37,
      id: 188,
      uniqueId: 'a9798d0a-6adc-478b-8e4f-33baf80855f7',
    },
    {
      descricao: 'Semana',
      sigla: 'S',
      codigo: 80,
      id: 189,
      uniqueId: '38f68f46-6e4a-422a-a5d9-8638542be39e',
    },
    {
      descricao: 'Seringa',
      sigla: 'Sg',
      codigo: 42,
      id: 191,
      uniqueId: '4c238dff-cd34-4774-9056-123b43dab3d6',
    },
    {
      descricao: 'SERINGA',
      sigla: 'SRG',
      codigo: 198,
      id: 190,
      uniqueId: '00445261-cca9-4382-95ca-6378286c007e',
    },
    {
      descricao: 'Serviço',
      sigla: 'Ser',
      codigo: 64,
      id: 192,
      uniqueId: '9a32d77e-d87c-486f-ab39-bd58d1e9bf31',
    },
    {
      descricao: 'SUPOSITORI',
      sigla: 'SUP',
      codigo: 199,
      id: 193,
      uniqueId: '75cea4e3-0d7f-49dc-b21d-c54f8f0e66ce',
    },
    {
      descricao: 'TABLETE',
      sigla: 'TBL',
      codigo: 201,
      id: 194,
      uniqueId: '3bfd6a10-08fd-4e97-b202-9c4f2fb6dee6',
    },
    {
      descricao: 'TALAO',
      sigla: 'TAL',
      codigo: 200,
      id: 195,
      uniqueId: '0c41e25f-b633-4d05-abd2-714821c825a2',
    },
    {
      descricao: 'Tambor',
      sigla: 'Tb',
      codigo: 55,
      id: 196,
      uniqueId: 'ebebdedf-324f-4931-9d47-4d47b9988705',
    },
    {
      descricao: 'Tambor',
      sigla: 'TB',
      codigo: 108,
      id: 197,
      uniqueId: 'dadc2684-1bee-4a5f-a4d2-5e6cf25a08bf',
    },
    {
      descricao: 'Taxa',
      sigla: 'Tax',
      codigo: 214,
      id: 198,
      uniqueId: '48b65630-3219-41db-ab9b-5482dcd5106a',
    },
    {
      descricao: 'Testes',
      sigla: 'Tes',
      codigo: 40,
      id: 199,
      uniqueId: 'df94885d-bb94-4af4-82cf-3e410f3f97e4',
    },
    {
      descricao: 'Toco',
      sigla: 'TO',
      codigo: 109,
      id: 200,
      uniqueId: 'd04a319f-8ade-4fe5-968a-820a7fe440bd',
    },
    {
      descricao: 'TONEL',
      sigla: 'TOL',
      codigo: 202,
      id: 201,
      uniqueId: 'bada374a-0854-4976-bbed-b52276a1d6d1',
    },
    {
      descricao: 'Tonelada',
      sigla: 'T',
      codigo: 4,
      id: 202,
      uniqueId: 'd520f766-a727-4713-8e26-451d29d3d330',
    },
    {
      descricao: 'TORRAO',
      sigla: 'TOR',
      codigo: 203,
      id: 203,
      uniqueId: 'cfcf6240-8a1e-4638-99bc-26171a6574f1',
    },
    {
      descricao: 'Tubete',
      sigla: 'Tb',
      codigo: 32,
      id: 204,
      uniqueId: 'fc6255b5-b125-4eca-a675-4e1bf2403457',
    },
    {
      descricao: 'Tubete',
      sigla: 'TU',
      codigo: 110,
      id: 205,
      uniqueId: 'f19db622-1c37-479c-ba5b-dfeb7eee2871',
    },
    {
      descricao: 'Tubo',
      sigla: 'Tub',
      codigo: 16,
      id: 207,
      uniqueId: 'bdef6aad-0559-46ad-98db-225408308179',
    },
    {
      descricao: 'TUBO',
      sigla: 'TUB',
      codigo: 204,
      id: 206,
      uniqueId: '841db38e-3602-42c5-b6b0-f6b7dfbb95d8',
    },
    {
      descricao: 'TURNO',
      sigla: 'TUR',
      codigo: 205,
      id: 208,
      uniqueId: '8f9461bb-1624-4d72-a052-759a58b558f0',
    },
    {
      descricao: 'Unidade',
      sigla: 'Un',
      codigo: 5,
      id: 209,
      uniqueId: '5fed3694-9349-4e84-bc54-cbf860721c50',
    },
    {
      descricao: 'Unidade x Mês',
      sigla: 'UnM',
      codigo: 224,
      id: 222,
      uniqueId: '3694382f-841c-4588-8b02-9e27071e56d6',
    },
    {
      descricao: 'VEICULO',
      sigla: 'VEC',
      codigo: 213,
      id: 210,
      uniqueId: '09c49822-8019-43a9-b225-fdb067bb9e0b',
    },
    {
      descricao: 'Vidro',
      sigla: 'Vdr',
      codigo: 57,
      id: 212,
      uniqueId: 'af98f94f-d1c1-4d1f-a24f-bf4462c1590d',
    },
    {
      descricao: 'VIDRO',
      sigla: 'VDR',
      codigo: 206,
      id: 211,
      uniqueId: 'd8d5548b-5af4-4434-b8d6-c544172d1b05',
    },
    {
      descricao: 'VIDROS',
      sigla: 'VDS',
      codigo: 207,
      id: 213,
      uniqueId: '0c7f3f36-d397-4e55-879b-054a00822fe0',
    },
    {
      descricao: 'VLGlobal',
      sigla: 'GL',
      codigo: 87,
      id: 214,
      uniqueId: 'c278ec7b-4e2f-42ab-88e1-d56d02a3365f',
    },
    {
      descricao: 'VOLUME',
      sigla: 'VOL',
      codigo: 208,
      id: 215,
      uniqueId: '2c4efb8f-2b48-4363-aa42-5e6ded0335b4',
    },
  ],
  modalidade: [
    {
      faseNegociacao: [
        {
          id: 8,
          name: 'Habilitação',
        },
      ],
      amparoLegal: [
        {
          uniqueId: '023d55e1-c4fd-4b5f-a8cd-7277683183c0',
          descricao: 'Lei 14.133/2021, Art. 28, II (concorrência)',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: true,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 2,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21, Artigo 28, II (concorrência). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '60fc75ab-c859-4b5f-8770-1238e868b11b',
          descricao:
            'Lei 14.133/2021, Art. 28, II (PNCP e Transfere Gov +Brasil)',
          ativo: true,
          pncp: true,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoMaisBrasil: 'LEI14133',
          codigoPncp: 2,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Concorrência Eletrônica programada com base na Lei 14.133/21, Artigo 28, II (concorrência). Será informada e publicada no PNCP - Plataforma Nacional de Contratações Públicas. Também será informada na plataforma Transfere Gov (+Brasil) com base na Lei 14.133/21. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
      ],
      finalidadeLicitacao: [
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 4,
              name: 'Melhor Técnica ou Conteúdo Artístico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 5,
              name: 'Maior Retorno Econômico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 9,
          name: 'Aquisição de Bens Especiais',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 4,
              name: 'Melhor Técnica ou Conteúdo Artístico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 5,
              name: 'Maior Retorno Econômico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 12,
          name: 'Contratação de Serviços Especiais',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 2,
              name: 'Maior Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 5,
              name: 'Maior Retorno Econômico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 3,
          name: 'Concessão',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 4,
              name: 'Melhor Técnica ou Conteúdo Artístico',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 5,
              name: 'Maior Retorno Econômico',
            },
          ],
          id: 10,
          name: 'Contratação de Obras e Serviços Comuns e Especiais de Engenharia',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
          ],
          id: 5,
          name: 'Registro de Preços',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 6,
          name: 'Compra (Aquisição)',
        },
      ],
      id: 1,
      name: 'Concorrência',
    },
    {
      faseNegociacao: [
        {
          id: 8,
          name: 'Habilitação',
        },
      ],
      amparoLegal: [],
      finalidadeLicitacao: [
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
          ],
          id: 3,
          name: 'Concessão',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 2,
                  name: 'Sem Lance',
                },
              ],
              id: 3,
              name: 'Técnica e Preço',
            },
          ],
          id: 4,
          name: 'Contratação de Obra, Serviços e Compras',
        },
      ],
      id: 2,
      name: 'Diálogo Competitivo',
    },
    {
      faseNegociacao: [
        {
          id: 4,
          name: 'Análise de Propostas',
        },
        {
          id: 5,
          name: 'Disputa – Lances',
        },
        {
          id: 7,
          name: 'Julgamento e Aceitação de Propostas',
        },
        {
          id: 8,
          name: 'Habilitação',
        },
      ],
      amparoLegal: [
        {
          uniqueId: '250b5492-db81-4c61-a46b-ac21d370af36',
          descricao: 'TESTE nova LEI OBS',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'teste@teste.com.br',
          codigoPncp: 123456,
          observacoes: {
            mensagens: [
              {
                tipo: 'Alerta',
                descricao: 'Visualização de alerta',
              },
              {
                tipo: 'Básico',
                descricao: 'mensagem fixada',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: true,
        },
        {
          uniqueId: '91c68d1b-3e65-4521-9abd-6698c0cddd8a',
          descricao: 'Lei 14.133/2021, Art. 28, I - (pregão eletrônico)',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: true,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 1,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na LeI 14.133/21, Artigo 28, I (pregão eletrônico). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas. Os procedimentos operacionais do Pregão no BBMNET segue as regras estabelecidas no DECRETO Nº 10.024, DE 20 DE SETEMBRO DE 2019. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '9d2deecb-7414-43dd-9c3f-64a54080b4cc',
          descricao: 'Lei 12.462/2011 - RDC- Reg. Dif. de Cont. Públicas',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12462.htm',
          codigoMaisBrasil: 'LEI12462',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '7868504d-bf72-4e97-a340-c07cb78d9ca8',
          descricao: 'Lei 10.520/2002 - Lei do Pregão',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/leis/2002/l10520.htm',
          codigoMaisBrasil: '123',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas. Os procedimentos operacionais do Pregão no BBMNET segue as regras estabelecidas no DECRETO Nº 10.024, DE 20 DE SETEMBRO DE 2019.',
              },
              {
                tipo: 'Alerta',
                descricao:
                  'Você está escolhendo uma lei antiga que perdeu a vigência, você realmente deseja continuar?',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '403d8c3b-7463-46bf-859a-a9b15296d8c2',
          descricao: '8.666/1993',
          ativo: false,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'INSTRUÇÃO NORMATIVA SEGES/ME Nº 67, DE 8 DE JULHO DE 2021',
          codigoMaisBrasil: 'LEI8666',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao: 'lei inadequada para o pregão ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '045bad5f-e6d0-4521-b32d-329825573626',
          descricao: '11.947/2009',
          ativo: false,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'a999be8b-d0b6-450a-a9c5-4cd17fc2ac60',
          descricao: '14.133/2021',
          ativo: false,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '7478dc36-4eaa-4768-bd2f-8c77775ce818',
          descricao:
            'Comitê Olímpico do Brasil - Manual de Compras e Contratos do COB',
          ativo: true,
          pncp: false,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://sistemas.cob.org.br/ProcessoDeCompras/Arquivos/ManualProcessoDeCompras.pdf',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa licitação é regida pelo Manual de Compras e Contratos do Comitê Olímpico do Brasil. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '06f73af8-a5bb-4ddd-8adb-2e0d50be59a4',
          descricao: 'Lei 10.520/2002 - Lei do Pregão',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/leis/2002/l10520.htm',
          codigoMaisBrasil: 'LEI10520',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas. Os procedimentos operacionais do Pregão Eletrônico no BBMNET segue as regras estabelecidas no DECRETO Nº 10.024, DE 20 DE SETEMBRO DE 2019.  ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '53cb9796-4385-4c99-84ae-0043e3cada68',
          descricao: 'Lei 13.303/2016 - Lei das Estatais',
          ativo: true,
          pncp: false,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13303.htm',
          codigoMaisBrasil: 'LEI13303',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas. No Portal BBMNET os procedimentos para a realização do Pregão para Estatais atendem aos requisitos previstos no Artigo 32 e seguintes da Lei 13.303/16',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '6915a90f-7a58-4b4c-9468-cd3825d9e45c',
          descricao: 'Lei 13.303/2016 - Lei das Estatais',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13303.htm',
          codigoMaisBrasil: 'LEI13303',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '1f1c1d29-2563-4cb3-8330-fd0126a3ad77',
          descricao: 'Lei 8.666/1993 - Lei Geral de Licitações',
          ativo: true,
          pncp: false,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/leis/l8666cons.htm',
          codigoMaisBrasil: 'LEI8666',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Esta licitação não será informada no PNCP - Portal Nacional de Contratações Publicas por não estar vinculada a nova Lei 14.133/2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'af88958c-2423-4a71-a55c-784693bc1f0c',
          descricao:
            'Fundação Zerbini - Regulamento de Compras Contratações (Lei 14.133/2021)',
          ativo: true,
          pncp: false,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.fz.org.br/wp-content/uploads/2020/01/regulamento-de-compras-e-contratacoes-fz-1.pdf',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'ESTA LICITAÇÃO OBEDECE INTEGRALMENTE AO DISPOSTO NO REGULAMENTO DE COMPRAS E CONTRATAÇÕES DA FUNDAÇÃO ZERBINI. O REGULAMENTO ESTÁ DISPONÍVEL PARA CONSULTAS NO SITIO DA INTERNET https://www.fz.org.br/wp-content/uploads/2020/01/regulamento-de-compras-e-contratacoes-fz-1.pdf',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '4cfcaaed-6b22-4ef9-ae2e-88d8fdd56228',
          descricao: 'Lei 8.666/1993 - Lei Geral de Licitações',
          ativo: false,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/leis/l8666cons.htm',
          codigoMaisBrasil: 'LEI8666',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa licitação será publicada com base na Lei 8.666/93 e será levada ao Portal Mais Brasil do Governo Federal, mas NÃO será publicada no PNPC - Portal Nacional de Contratações Publicas exigido apenas para licitações realizadas a Luz da Lei 14.133/2021.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'f9c2c7ba-61b9-4645-a587-7d40eb6134be',
          descricao:
            'Lei 14.133/2021, Art. 28, I (PNCP e Transfere Gov +Brasil)',
          ativo: true,
          pncp: true,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoMaisBrasil: 'LEI14133',
          codigoPncp: 1,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'MUITA ATENÇAO: Informamos que a plataforma Transfere Gov (Mais Brasil) que processa informações de transferencias de recursos da União passou a receber informações de licitações configuradas as luz da nova Lei 14.133/2021. Dessa forma, essa licitação será informada à plataforma Transfere Gov (Mais Brasil)  do Governo Federal na forma da Lei 14.133/21 e também será publicada no PNCP-Portal Nacional de Contratações Publicas na forma da Lei 14.133/21, Art. 28, I (pregão eletrônico). Os procedimentos operacionais do Pregão no BBMNET seguem as regras estabelecidas no DECRETO Nº 10.024, DE 20 DE SETEMBRO DE 2019',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
      ],
      finalidadeLicitacao: [
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 8,
          name: 'Aquisição de Bens Comuns',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
              ],
              id: 2,
              name: 'Maior Preço',
            },
          ],
          id: 11,
          name: 'Contratação de Serviços Comuns',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
                {
                  id: 3,
                  name: 'Aberto e Fechado',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 13,
          name: 'Registro de Preços para Bens ou Serviços Comuns',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
              ],
              id: 2,
              name: 'Maior Preço',
            },
          ],
          id: 14,
          name: 'Concessão/Alienação',
        },
      ],
      id: 3,
      name: 'Pregão (Setor público)',
    },
    {
      faseNegociacao: [
        {
          id: 5,
          name: 'Disputa – Lances',
        },
      ],
      amparoLegal: [],
      finalidadeLicitacao: [
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 1,
                  name: 'Aberto',
                },
              ],
              id: 7,
              name: 'Maior Lance',
            },
          ],
          id: 7,
          name: 'Venda de Bens Imóveis ou Bens Móveis Inservíveis ou Legalmente Apreendidos',
        },
      ],
      id: 4,
      name: 'Leilão',
    },
    {
      faseNegociacao: [
        {
          id: 5,
          name: 'Disputa – Lances',
        },
        {
          id: 7,
          name: 'Julgamento e Aceitação de Propostas',
        },
      ],
      amparoLegal: [
        {
          uniqueId: '1606d3bc-a3ae-4eda-bc19-39778824fd1b',
          descricao: 'Lei 14.133/2021, Art. 75, I',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: true,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 18,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21 (dispensa). Sendo assim, será informada e publicada na PNCP - Plataforma Nacional de Contratações Publicas. No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'd86ccc0b-7604-4179-814e-dbade0121951',
          descricao: 'Lei 14.133/2021, Art. 75, II',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 19,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21 (dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '68b7133e-6de6-4c41-a917-9742b5473aad',
          descricao: 'Lei 14.133/2021, Art. 75, III, a',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 20,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'c048c0d0-49a4-4f7c-8a4b-210f9c8a437a',
          descricao: 'Lei 14.133/2021, Art. 75, III, b',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 21,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'e547349f-2922-47a7-86c3-1d840b4f9f4e',
          descricao: 'Lei 13.303/2016 - Lei das Estatais',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13303.htm',
          codigoMaisBrasil: 'LEI13303',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '06366a27-abc0-4a57-8c0b-4f4c83ea9586',
          descricao: 'Lei 14.133/2021, Art. 75, IV, e',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 26,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'c6520aa6-6526-4982-adfe-7d022504e79d',
          descricao: 'Lei 14.133/2021, Art. 75, IV, f',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 27,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '26e351af-802c-4560-ad98-1cd737e6a3db',
          descricao: 'Lei 14.133/2021, Art. 75, IV, g',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 28,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '9dd16017-a1eb-4696-b25b-36280b59a28b',
          descricao: 'Lei 14.133/2021, Art. 75, IV, h',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 29,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '6a052591-57f0-4db4-a079-932048f997ce',
          descricao: 'Lei 14.133/2021, Art. 75, IV, a',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 22,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '4e799e32-c1b9-44d6-9f52-cef299036858',
          descricao: '8.666/1993 - Lei Geral de Licitações',
          ativo: true,
          pncp: false,
          maisBrasil: true,
          leiPadrao: true,
          link: 'https://www.planalto.gov.br/ccivil_03/leis/l8666cons.htm',
          codigoMaisBrasil: 'LEI8666',
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com uma Legislação diversa da Lei 14.133/21. Sendo assim, não será informada e/ou publicada no PNCP - Plataforma Nacional de Contratações Publicas. No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: true,
          minutosAcrescidosEmpatePropostaLances: 0,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '55b4334f-ea7a-44db-915d-e2d35b7abb04',
          descricao: 'Lei 14.133/2021, Art. 75, IX',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 39,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '4b27356d-3c30-43a3-8f21-f06c2736dddf',
          descricao: 'Lei 14.133/2021, Art. 75, XII',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 42,
          observacoes: null,
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '657acfc3-e6be-45bb-bdb6-e87c13cdd531',
          descricao: 'Lei 14.133/2021, Art. 75, XIII',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 43,
          observacoes: null,
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'd204e827-8c8f-4055-85c3-0373975375f0',
          descricao: '14.133/2021',
          ativo: false,
          pncp: false,
          maisBrasil: true,
          leiPadrao: false,
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'b2bac527-38b1-46e0-8c21-061c5bd8b770',
          descricao: 'Lei 14.133/2021, Art. 75, IV, i',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 30,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'e6fb4d70-4f26-4ddc-9735-d20bd9255adc',
          descricao: 'Lei 14.133/2021, Art. 75, IV, j',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 31,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '46d08069-a412-4716-9762-cf72445ee473',
          descricao: 'Lei 14.133/2021, Art. 75, IV, k',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 32,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '8052d499-b0a9-4f2c-9d5d-17a55de4aadb',
          descricao: 'Lei 14.133/2021, Art. 75, IV, l',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 33,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '3f5babd6-b80c-49c2-81de-99bda29d67e9',
          descricao: 'Lei 14.133/2021, Art. 75, IV, m',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 34,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021.',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'd9230e22-76d9-40c1-b22d-7469cddcb562',
          descricao: 'Lei 14.133/2021, Art. 75, IV, c',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 24,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '2bb1559e-62fc-4bd8-8f88-3e17f0578122',
          descricao: 'Lei 14.133/2021, Art. 75, IV, d',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 25,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'cb06b93a-36c2-4a4e-a25c-d99e59be4630',
          descricao: 'Lei 14.133/2021, Art. 75, X',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 40,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'ed964bc7-bb68-4b49-b1d3-1c0307b6a79e',
          descricao: 'Lei 14.133/2021, Art. 75, XI',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 41,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'a72029b9-74cd-4cfb-9403-4ac73c8d5920',
          descricao: 'Lei 14.133/2021, Art. 75, XV',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 45,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '7e26f823-d29f-465e-9551-8f57e7f82618',
          descricao: 'Lei 14.133/2021, Art. 75, XVI',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 46,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '83047a46-48f1-49a7-876c-49bc69587d84',
          descricao: 'Lei 14.133/2021, Art. 75, VI',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 36,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '505253a6-78ec-4a09-a43a-d4104b1fec11',
          descricao: 'Lei 14.133/2021, Art. 75, IV, a',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 22,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '2c2abf73-f5dd-4f15-b052-4a365af60032',
          descricao: 'Lei 14.133/2021, Art. 75, VII',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 37,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '2b909a36-a466-4630-bd43-a84ee1101bb4',
          descricao: 'Lei 14.133/2021, Art. 75, XIV',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 44,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'cd92ce17-d77e-4dff-8185-3696af9098fa',
          descricao:
            'LEIS 14.133/21, Art.75, II (PNCP e Transfere Gov +Brasil)',
          ativo: true,
          pncp: true,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoMaisBrasil: 'LEI14133',
          codigoPncp: 19,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  ' Informamos que a plataforma MAIS BRASIL que processa informações de transferências de recursos da União passou a receber informações de licitações configuradas a luz da nova Lei 14.133/2021. Dessa forma, essa licitação será informada à plataforma Transfere Gov (+BRASIL) do Governo Federal e no PNCP-Portal Nacional de Contratações Publicas na forma da Lei 14.133/21, Art. 75, II (DISPENSA). O procedimento operacional da Dispensa no BBMNET obedece parcialmente a INSTRUÇÃO NORMATIVA SEGES/ME Nº 67, DE 8 DE JULHO DE 2021 ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '9dda40a3-8913-4d2c-a3b1-48f590249aa0',
          descricao: 'Lei 14.133/2021, Art. 75, IV, b',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 23,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: 'bb568fec-d922-43c0-9d17-068d3268484a',
          descricao: 'Lei 14.133/2021, Art. 75, V',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 35,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '5fd756e4-7ae6-4f77-bfe5-adfd792040e9',
          descricao: 'Lei 14.133/2021, Art. 75, VIII',
          ativo: true,
          pncp: true,
          maisBrasil: false,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoPncp: 38,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Essa Licitação está programada com fulcro na Lei 14.133/21(dispensa). Sendo assim, será informada e publicada no PNCP - Plataforma Nacional de Contratações Publicas . No Portal BBMNET os procedimentos para a realização da dispensa atendem aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. ',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '6e9862eb-c2d7-4920-94d7-78de66b05c08',
          descricao:
            'LEIS 14.133/21, Art.75, III (PNCP e Transfere Gov + Brasil)))',
          ativo: true,
          pncp: true,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoMaisBrasil: 'LEI14133',
          codigoPncp: 20,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'MUITA ATENÇAO: Informamos que a plataforma MAIS BRASIL que processa informações de transferências de recursos da União passou a receber informações de licitações configuradas a luz da nova Lei 14.133/2021. Dessa forma, essa licitação será informada à plataforma MAIS BRASIL do Governo Federal na forma da Lei 14.133/21 e será publicada no PNCP-Portal Nacional de Contratações Publicas na forma da Lei 14.133/21, Art. 75, III (DISPENSA). No Portal BBMNET os procedimentos operacionais para arealização da dispensa atendem parcialmente aos requisitos previstos na IN (Instrução Normativa) Seges/Ministério da Economia nº 67, de 8 de julho de 2021. \n\n\n',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
        {
          uniqueId: '35d83dd8-3465-4d51-bc2f-9a5bc71ba42b',
          descricao: 'LEIS 14.133/21, Art.75, I (PNCP e Transfere Gov +Brasil)',
          ativo: true,
          pncp: true,
          maisBrasil: true,
          leiPadrao: false,
          link: 'https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14133.htm',
          codigoMaisBrasil: 'LEI14133',
          codigoPncp: 18,
          observacoes: {
            mensagens: [
              {
                tipo: 'Básico',
                descricao:
                  'Informamos que a plataforma MAIS BRASIL que processa informações de transferências de recursos da União passou a receber informações de licitações configuradas a luz da nova Lei 14.133/2021. Dessa forma, essa licitação será informada à plataforma Transfere Gov (+Brasil) do Governo Federal e será publicada no PNCP-Portal Nacional de Contratações Publicas na forma da Lei 14.133/2021, Art. 75, I  (DISPENSA). No Portal BBMNET os procedimentos para a realização da dispensa atendem parcialmente aos requisitos previstos na IN (Instrução Normativa)\nSeges/Ministério da Economia nº 67, de 8 de julho de 2021. \n',
              },
            ],
          },
          exigirDocumentosHabilitacaoProposta: false,
          minutosAcrescidosEmpatePropostaLances: 5,
          permiteAlterarAposPublicacao: false,
        },
      ],
      finalidadeLicitacao: [
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 6,
                  name: 'Prorrogação',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
                {
                  id: 5,
                  name: 'Sem Prorrogação',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 6,
                  name: 'Prorrogação',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
                {
                  id: 5,
                  name: 'Sem Prorrogação',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 1,
          name: 'Aquisição de Bens',
        },
        {
          criterioJulgamento: [
            {
              modoDisputa: [
                {
                  id: 6,
                  name: 'Prorrogação',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
                {
                  id: 5,
                  name: 'Sem Prorrogação',
                },
              ],
              id: 1,
              name: 'Menor Preço',
            },
            {
              modoDisputa: [
                {
                  id: 6,
                  name: 'Prorrogação',
                },
                {
                  id: 2,
                  name: 'Sem Lance',
                },
                {
                  id: 5,
                  name: 'Sem Prorrogação',
                },
              ],
              id: 6,
              name: 'Maior Desconto',
            },
          ],
          id: 2,
          name: 'Contratação de Serviços',
        },
      ],
      id: 5,
      name: 'Dispensa',
    },
  ],
  redefinicaoValorItens: [
    {
      id: 1,
      name: 'Sim',
    },
    {
      id: 2,
      name: 'Não',
    },
  ],
  ramoAtividade: [
      {
        uniqueId: '30c87b65-013a-41ed-ba67-f00f373148e0',
        descricao: 'Alimentação',
        ativo: true,
        segmentos: [],
      },
      {
        uniqueId: '18c746e4-2a80-4db3-b671-d9811c1b9916',
        descricao: 'Saúde',
        ativo: true,
        segmentos: [],
      },
      {
        uniqueId: 'e251bad4-5b7c-4cee-a2f1-8a1f8e6f4a61',
        descricao: 'Produtos de limpeza',
        ativo: true,
        segmentos: [],
      },
      {
        uniqueId: 'e0480944-d883-40f4-bd31-984be69bbd41',
        descricao: 'Engenharia - serviços',
        ativo: true,
        segmentos: [],
      },
      {
        uniqueId: 'fe29fa0f-e4c9-44be-bc65-31669c0ddfee',
        descricao: 'Engenharia - materiais',
        ativo: true,
        segmentos: [],
      },
      {
        uniqueId: 'b6b01d45-81ae-47eb-a9eb-e9b767e2b142',
        descricao: 'Instalações',
        ativo: true,
        segmentos: [],
      },
      {
        uniqueId: '6741e12d-c3f6-43e0-9688-a57b1675d4fe',
        descricao: 'Transportes rodoviários',
        ativo: true,
        segmentos: [],
      },
      {
        uniqueId: '38aaa2b6-13b1-4a7d-8cc8-519da3d49fd3',
        descricao: 'Informática',
        ativo: true,
        segmentos: [],
      },
      {
        uniqueId: 'd5522af0-f5a6-4b45-921f-b2bd11c6bc1b',
        descricao: 'Escritório e gráfica',
        ativo: true,
        segmentos: [],
      },
      {
        uniqueId: '96f5b56c-f064-45ee-ae99-ca4d06cfe2be',
        descricao: 'Esportivos/musicais',
        ativo: true,
        segmentos: [],
      },
      {
        uniqueId: 'eae77761-0a98-46a4-9ba4-82390b32f27c',
        descricao: 'Segurança/Proteção',
        ativo: true,
        segmentos: [],
      },
  ],
};
