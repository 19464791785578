import { api, apiAuth } from '@libs/api';
import { editaisEmptyMock, editaisMock } from '../utils/editaisMock';
import { dadosSuporteMock } from '../utils/dadosSuporteMock';
import { editalMock } from '../utils/editalMock';

const ApiRoutes = {
  authentication: {
    login: (data: unknown) => true,
  },
  editais: {
    getEditais: (data: unknown) => api.get(`edital/all`),
    getEdital: (id: unknown) => api.get(`edital/${id}`),
    getDadosSuporte: () => api.get(`EditaisExternal/DadosSuporte`),
  },
};

export const login = (data: unknown) =>
  ApiRoutes.authentication
    .login(data)
    .then((response) => Promise.resolve(response.data))
    .catch((e) => Promise.reject(e.response));

export const getEditais = (data: unknown) =>
  ApiRoutes.editais
    .getEditais(data)
    .then((response) => Promise.resolve(response.data))
    .catch((e) => Promise.reject(e.response));

export const getEdital = (data: unknown) =>
  ApiRoutes.editais
    .getEdital(data)
    .then((response) => Promise.resolve(response.data))
    .catch((e) => Promise.reject(e.response));

export const getDadosSuporte = () =>
  ApiRoutes.editais
    .getDadosSuporte()
    .then((response) => Promise.resolve(response.data))
    .catch((e) => Promise.reject(e.response));

// export const getDadosSuporte = () => Promise.resolve(dadosSuporteMock);
// // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
// export const getEditais = (data: unknown) => Promise.resolve(editaisMock);
// // ) => Promise.resolve(editaisEmptyMock);
// // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
// export const getEdital = (data: unknown) => Promise.resolve(editalMock);

export default ApiRoutes;
